import React from 'react';
import { GoogleButton } from './GoogleButton';
import 'bootstrap-icons/font/bootstrap-icons.css';
import Accordion from "./Accordion";
import ArrowIcon from "../../../assets/Icons/ArrowIcon";



function Footer () {


    return (
        <div className="EstimationPageFooter">
            <div className="FooterIconsGroup">
                <a className="btn" href="https://www.facebook.com/filip.szemraj.33" role="button">
                    <i className="bi bi-facebook"></i>
                </a>
                <a className="btn disabled" href="#!" role="button">
                    <i className="bi bi-twitter"></i>
                </a>

                <div className="btn GoogleButton">
                    <GoogleButton/>
                </div>

                <a className="btn" href="https://www.instagram.com/flipdruk/" role="button">
                    <i className="bi bi-instagram"></i>
                </a>

                <a className="btn" href="https://www.linkedin.com/in/filip-szemraj/" role="button">
                    <i className="bi bi-linkedin"></i>
                </a>

                <a className="btn" href="https://github.com/FilipSzemraj" role="button">
                    <i className="bi bi-github"></i>
                </a>
            </div>

            <div className="ContactFooter">
                <section className="rowInfo">
                    <i className="bi bi-telephone"></i>
                    <p>+48 733 359 559</p>
                </section>
                <section className="rowInfo">
                    <i className="bi bi-envelope-at"></i>
                    <p>kontakt@flipdruk.pl</p>
                </section>
                <section className="rowInfo">

                    <a href="https://g.co/kgs/1qMakZe" style={{textDecoration:'none', color:'var(--font-color)'}}>
                        <i className="bi bi-geo-alt" ></i> Przejdź do lokalizacji</a>
                </section>

                <ArrowIcon idOfDivToScrollInto={".outerDivHeader"} />
            </div>

            <div className="FooterCopyright">
                <p>Copyright &#169; 2024 - Filip Szemraj
                </p>
            </div>
            <div className="FooterFAQ">
                <p>Najczęściej zadawane pytania:</p>
                <Accordion />
            </div>
        </div>
    );
}

export default Footer;

