type ImagesObject = Record<string, string>;

interface ImportAllProps{
    forWhichComponent: string,
    forWhichCard: string,
    size: string;
}

const imagesContext = require.context("../Images/", true, /\.(webp|mp4)$/);

const importAll = ({
                       forWhichComponent,
                       forWhichCard,
                       size
                   }: ImportAllProps): ImagesObject => {
    if(!size) return {};


    try{
        const images: ImagesObject = {};

        imagesContext.keys().forEach((key: string) => {

            if(
                key.includes(`./${forWhichComponent}`) &&
                (!forWhichCard || key.includes(`/${forWhichCard}/`)) &&
                key.includes(`/${size}`)
            ) {
                const fileName = key.replace('./', '').replace(/\.(webp|mp4)$/, '');
                const match = fileName.match(/(\d+)/);
                const baseNumber = match ? match[0] : null;
                // console.log("Image Path:", imagesContext(key).default || imagesContext(key));


                if (baseNumber !== null) {
                    images[baseNumber] = imagesContext(key).default || imagesContext(key);
                }
                // console.log(key);
            }
        });

        return images;
    }catch(err){
        console.error("Error importing images: ", err);
        return {};
    }
}

export {importAll};