import React from 'react';
import { useState, useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';

export function GoogleButton() {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <>
          <a  ref={target} style={{ backgroundColor: '#dd4b39' }} onClick={() => setShow(!show)}>
              <i className="bi bi-google"></i>
          </a>

         
      <Overlay target={target.current} show={show} placement="top">
        {({
          placement: _placement,
          arrowProps: _arrowProps,
          show: _show,
          popper: _popper,
          hasDoneInitialMeasure: _hasDoneInitialMeasure,
          ...props
        }) => (
          <div
            {...props}
            style={{
                backgroundColor: '#dd4b39',
                padding: '0.25rem 0.5rem',
                color: 'black',
                width:"fit-content",
                borderRadius: '0.75rem',
                ...props.style,
            }}
          >
            filippszemraj@gmail.com
          </div>
        )}
      </Overlay>
    </>
  );
}
