import React, {useContext, useEffect, useMemo} from 'react';
import { Carousel } from 'react-bootstrap';

import CarouselImage from './CarouselImage';
import HeaderText from './HeaderText';
import ArrowIcon from '../../assets/Icons/ArrowIcon';
import "./Header.css";
import { useState } from 'react';
import useIntersectionObserver from "../IntersectionObserver";
import {IsMobileContext} from "../IsMobileContext";
import {importAll} from "../../assets/functions/importAllUtils";

const sizeMap = {
    "mobile_high": 7,
    "mobile_low": 7,
    "desktop_low": 5,
    "desktop_high": 4,
}

const carouselItems = [
    {
        text: "First slide",
        shadowColor: '#FFD700',
    },
    {
        text: "Second slide",
        shadowColor: '#FF0000',
    },
    {
        text: "Third slide",
        shadowColor: '#0000FF',
    },
    {
        text: "Fourth slide",
        shadowColor: '#808080',
    },
    {
        text: "Fifth slide",
        shadowColor: '#FFD700',
    },
    {
        text: "Sixth slide",
        shadowColor: '#FF0000',
    },
    {
        text: "Seventh slide",
        shadowColor: '#0000FF',
    },
    {
        text: "Eighth slide",
        shadowColor: '#808080',
    },
];

const Header: React.FC =() => {
    const [isVisible, carouselRef] = useIntersectionObserver({
        root: null,
        rootMargin: "0px",
        threshold: 0.5,
        maintainObservation: true,
    });
    const {isHeightFixed, size} = useContext(IsMobileContext);
    const defaultInterval = 5500;
    const [interval, setInterval] = useState<number | null>(defaultInterval);
    const images = useMemo(() => (size ? importAll({
        forWhichComponent: "carouselImage",
        forWhichCard: "",
        size: size
    }) : {}), [size]);

    /*const images = {
        mobile_high_quality: importAll("carouselImage","",false),
        mobile_low_quality: importAll(require.context('../../assets/carouselImage/mobile_low_quality', false, /\.(webp)$/), "mobile_low_quality"),
        desktop_high_quality: importAll(require.context('../../assets/carouselImage/desktop_high_quality', false, /\.(webp)$/), "desktop_high_quality"),
        desktop_low_quality: importAll(require.context('../../assets/carouselImage/desktop_low_quality', false, /\.(webp)$/), "desktop_low_quality"),
    };*/




    /*const footerRef = useRef<HTMLDivElement>(null);


    const [viewBox, setViewBox] = useState("0 0 1600 900");

    const { width, height } = useParentSize(footerRef);*/

    const changeShadowColor = (newColor: string) => {
        document.documentElement.style.setProperty('--shadow-color', newColor);

        // const encodedColor = encodeURIComponent(newColor);


        // const svgBackground = `no-repeat url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" fill="${encodedColor}"><rect fill="%23450f37" width="100%" height="100%"/><path d="M0 0v47.6l5-2c1 0 11 3 12 0 2 3 6-3 6 0 0-4 12 3 12 0 0 3 15-1 17 0 2-2 5-1 6 0 0-2 6 2 6 0s2 4 4 0c5 2 12-3 16 0 2-2 4-3 4 0 0 2 6-1 6 0 1 4 15-2 17 0h7c0 1 3-3 6 0h17c2 2 3 1 6 0h6c1-2 21-1 24 0 2 1 4 2 6 0 0-1 22 4 24 0 0 0 5-3 5 0 2-2 10 2 12 0 2 2 6 1 6 0 2 3 4-2 6 0 1 0 25-2 25 1l10-1c3 1 6 6 7 0 1 5 4-2 6 0 2-2 4 3 5 0h12c6 1 36 2 36 0 0 2 3 0 6 0h6c5-2 7 4 11 0 2 0 15 2 17 0h13c3-4 5 1 7 0h29c0-3 6 0 6 0h5c0 2 16-1 18 0 1 4 9-1 12 0s6-2 6 0c8-2 3 4 13 0h10c3 4 19 1 19 0 2 0 21 1 23-1 1 4 3-1 6 1 1 2 11-1 12-1 3 3 9 0 12 1 3-4 6 1 6 0h6c0-3 5 1 6-1 0 3 2 1 4 1 3 4 10-1 13 0 3-2 6-1 6 0 2 2 2 0 6 0 1-2 6 2 6 0 2 0 4 5 6 0h18c2 3 4 1 6 0l6-1c3 2 12 3 17 1 14 3 18 1 24 0 2-1 3 3 5 0 6 2 10-1 16 0 1 3 6 0 9 0 0-2 3 2 5 0 6-6 8 7 13 0 0-2 5 2 5 0 3 3 10 0 10 0 1 2 5-2 8 0 3-1 8 3 12 0h6c2 1 10 4 12 0h6c1-1 5 2 6 0 1 2 4-1 6 0 0-2 5 3 6-1 2 1 6 5 5 1 1 1 3-2 6 0 2-1 5 3 6 0 0 1 6 2 6 0 2 3 4-4 6 0 0-2 3 2 6 0 3 0 6 3 6 0 5 3 8-1 13 0 3-4 6 1 6 0h5c0-1 9 2 12 0 1-1 9 3 11 0h6c2 2 4 4 7 0 3 2 5-4 5-1 10 4 15-2 18 2 0-1 6 2 6-2 0 0 6-2 6 1 1 6 12 2 12 0 1 3 4-3 7 2 2-2 5 2 5 0 1 5 4-5 6 0 2-1 4 2 6 0 1 3 1 0 5 0V0H0Z"></path></svg>')`;
        // document.documentElement.style.setProperty('--background-image', svgBackground);
        // document.documentElement.style.setProperty('--font-color', newColor);
    };

    const handleSlideEnd = (newIndex: number) => {
        // Trigger your custom function here when slide ends
        // console.log(`Slide changed to index: ${newIndex}`);

        // Example: change shadow color dynamically
        const newColor = carouselItems[newIndex].shadowColor; // Assuming shadowColor is in your item data
        changeShadowColor(newColor);
    };



    useEffect(() => {
        if (isVisible) {
            // console.log("jest widoczny");
            setInterval(defaultInterval);
        } else {
            // console.log("jest niewidoczny");
            setInterval(null);
        }
    }, [isVisible]);

    if (size === null) {
        return (
            <div className="outerDivHeader">
                <div className="innerDivHeader"></div>
            </div>
        );
    }

    return(
        <div className="outerDivHeader">
            <div className="innerDivHeader snap-point">



            <HeaderText />

            <ArrowIcon idOfDivToScrollInto=".IntroductionWrapper"/>

<div ref={carouselRef}>
            <Carousel className="carousel-slide"
                      prevIcon={""} nextIcon={""}
                      onSlid={(e) => handleSlideEnd(e)}
                      interval={interval}
            >

                {carouselItems.slice(0, sizeMap[size]).map((item, index) => (
                    <Carousel.Item key={index}>
                        <CarouselImage text={item.text} src={`${images[(index+1)]}`} />
                        <Carousel.Caption>
                        </Carousel.Caption>
                    </Carousel.Item>
                ))}
          </Carousel>
</div>
            </div>
            {isHeightFixed ? null : (
            <div className="footerDivHeader"
            >

            </div>
            )}
        </div>
    );
}

export default Header;