import React, { useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import useIntersectionObserver from "../IntersectionObserver";
import MapWrapper from "./MapWrapper";


gsap.registerPlugin(ScrollTrigger);


const TextForIntroduction : React.FC = () => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [isVisible, mapRef] = useIntersectionObserver({
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
        maintainObservation: false,
    });

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {
            const elements = gsap.utils.toArray<HTMLElement>(".leftAlign, .rightAlign") as HTMLDivElement[]; // Use type assertion
            elements.forEach((box) => {
                gsap.fromTo(box, {
                    x:'-100%',
                },{
                    x: '0%',
                    duration:1,
                    scrollTrigger: {
                        trigger: wrapperRef.current,
                        start: 'top bottom',
                        // end: 'top top',
                        // scrub: true,
                        // markers:true,
                    },
                });
            });
        }, wrapperRef); // <- Scope!

        return () => ctx.revert(); // <- Cleanup!
    }, []);

    return(
        <div className="outerTextDiv" ref={wrapperRef}>
            <div className="rightAlign">
                <p>Mam na imię Filip</p>
                <p>Dziękuję za odwiedzenie mojej strony.</p>
            </div>

            <div className="rightAlign" >
                <p>Swoje usługi wykonuję z Kielc,
                    więcej projektów wykonanych przeze mnie można zobaczyć
                    na&nbsp;załączonym <a href="https://www.instagram.com/flipdruk/">Instagramie.</a></p>
            </div>

            {/*<div className="lineWithArrow" ref={thirdRowRef}>
                <p style={{width: '80%'}}>W celu szybszej nawigacji proponuje korzystać z </p>
            </div>*/}
            <div className="MapIntroductionCard" style={{backgroundColor:'white',}} ref={mapRef}>
                { isVisible && (
                <div className="MapIntroductionBody" >
                    <MapWrapper />
                </div>
                )}
            </div>

            <div className="leftAlign" >
            <p>Odkryj zalety ekologicznego druku 3D, który w specjalistycznych warunkach kompostowania rozkłada się poniżej 90 dni.</p>
            </div>
        </div>
    );
}

export default TextForIntroduction;