import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import CardWith3DModel from "./CardWith3DModel";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import ScrollToPlugin from 'gsap/ScrollToPlugin';
import cupholderImage from '../../assets/cupholder.jpg';
import cupholderVideo from '../../assets/showcaseImagesAndVideos/cupholderVideo.mp4';
// import {CupholderImages, ShelfImages, QRImages, MovableObjectImages} from "./ImagesForCardsImports";


import "./CardsRow.css";
import {IsMobileContext} from "../IsMobileContext";
import {importAll} from "../../assets/functions/importAllUtils";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin, useGSAP);


const CardsRow: React.FC = () => {

    const innerWrapperRef = useRef<HTMLDivElement | null>(null);
    const t = useRef<GSAPTween | null>(null);
    const factor = useRef(0);
    const progressIndex = useRef(0); // default to 0

    const {isHeightFixed, size} = useContext(IsMobileContext);



    const CupholderImages = useMemo(() => (size ? importAll({
        forWhichComponent: "CardsImage",
        forWhichCard: "cupholder",
        size: size
    }) : {}), [size]);
    const ShelfImages = useMemo(() => (size ? importAll({
        forWhichComponent: "CardsImage",
        forWhichCard: "shelf",
        size: size
    }) : {}), [size]);
    const QRImages = useMemo(() => (size ? importAll({
        forWhichComponent: "CardsImage",
        forWhichCard: "QR",
        size: size
    }) : {}), [size]);
    const MovableObjectImages = useMemo(() => (size ? importAll({
        forWhichComponent: "CardsImage",
        forWhichCard: "movableObject",
        size: size
    }) : {}), [size]);





    const items = [
        {
            name: 'Wysuwana półka pod laptopa',
            modelUrl: 'podstawka.stl',
            modelDemoImage: 'podstawka.png',
            printingTime: '22h',
            description: "Funkcjonalna wysuwana półka stworzona specjalnie dla laptopa Lenovo Legion Slim 5-14. Projekt zapewnia pełny dostęp do złącz urządzenia oraz otwory wentylacyjne dla optymalnego chłodzenia. Montaż jest intuicyjny – wystarczy przykręcić dwie części do blatu, a następnie połączyć je szynami z pozostałymi elementami. Maksymalne dopasowanie gwarantuje stabilność i wygodę użytkowania.",
            secondPartDescription:"",
            modelImages: ShelfImages

        },
        {
            name: 'Kod QR',
            modelUrl: 'QR.stl',
            modelDemoImage: 'QR.png',
            printingTime: '1h',
            description: "Kod QR, który możesz wykorzystać jako kod dostępu do Wi-Fi lub link do menu czy strony internetowej. Idealny do restauracji, kawiarni czy biura. Nie tylko funkcjonalny, ale także może stanowić ciekawy element dekoracyjny - jego bryła nie musi się ograniczać do tej przedstawionej na zdjęciu😉",
            secondPartDescription:"Przy większej ilości zamówień cena może drastycznie spaść i przy odpowiednim projekcie można rozważać użycie czegoś w tym rodzaju jako wizytówki.",
            modelImages: QRImages
        },
        {
            name: 'Uchwyt na napoje',
            modelUrl: 'object.stl',
            modelDemoImage: 'object.png',
            printingTime: '32h',
            description: "Unikalny uchwyt na napoje zaprojektowany od podstaw i precyzyjnie dopasowany do Fiata Doblo. Montaż jest prosty i polega na zamocowaniu nakładki, która zahacza o śrubę montażową fotela oraz śrubę od kokpitu zatopioną w modelu – dokładnie tak, jak widać na zdjęciu. Solidne mocowanie gwarantuje stabilność nawet na wyboistych drogach.",
            secondPartDescription:"W motoryzacji, jak i w wielu innych dziedzinach druk 3D może wspomóc nas w ramach drukowania zamienników, czy rozszerzeń funkcjonalności.",
            modelImages: CupholderImages
        },
        {
            name: 'Ruchomy obiekt',
            modelUrl: 'ruchomyObiekt.stl',
            modelDemoImage: 'ruchomyObiekt.png',
            printingTime: '1.5h',
            description: "Model pokazujący możliwości druku 3D w tworzeniu ruchomych elementów. Całość wydrukowana w jednym procesie, bez konieczności montażu – wszystkie części są od razu ruchome. Doskonały przykład na to, jak zaawansowana technologia pozwala realizować skomplikowane projekty.",
            secondPartDescription:"",
            modelImages: MovableObjectImages
        }
    ];

    const { contextSafe } = useGSAP(() => {
        if(innerWrapperRef.current) {
        const slides = gsap.utils.toArray('.inner');
        factor.current = 1 / (slides.length - 1);
        const cont = innerWrapperRef.current.querySelector('.ShowcaseBodyFirst') as HTMLDivElement;


        t.current = gsap.to(slides, {
            xPercent: -100 * (slides.length),
            ease: 'none',
            paused: true,
           /* scrollTrigger: {
                trigger: cont,
                start: 'top top',
                end: '+=' + 100 * slides.length + '%',
                pin: true,
                scrub: true,
                snap: factor.current,
                onUpdate: (s) => {
                    const newVal = gsap.utils.snap(factor.current, s.progress);
                    if (newVal !== progressIndex.current) {
                        progressIndex.current = newVal;
                    }
                },
                // markers: true,
            },*/
        });
        }
    });


    const handleScroll = contextSafe((d: boolean) => {
        console.log("wykonalem sie");
        const { current: _i } = progressIndex;
        // const { scrollTrigger: st } = t.current!;
        const { current: _f } = factor;
        // const start = st!.start;
        // const distance = st!.end - start;
        let newProgressIndex;

        if (d && _i < 1) {
            newProgressIndex = _i + _f;
            console.log('forward', _i, newProgressIndex);
            gsap.to(t.current, {
                progress: newProgressIndex, // Animate to the new progress
                duration: 1, // Duration of the animation (adjust as needed)
                ease: "power2.inOut", // Smooth easing (optional, customize as you like)
            });
            progressIndex.current = newProgressIndex;
        } else if (!d && _i > 0) {
            newProgressIndex = _i - _f;
            console.log('back', _i, newProgressIndex);
            gsap.to(t.current, {
                progress: newProgressIndex,
                duration: 1,
                ease: "power2.inOut",
            });
            progressIndex.current = newProgressIndex;
        } else if (!d && _i === 0) {
            gsap.to(t.current, {
                progress: 0,
                duration: 1,
                ease: "power2.inOut",
            });
        } else if (d && _i === 1) {
            gsap.to(t.current, {
                progress: 1,
                duration: 1,
                ease: "power2.inOut",
            });
        }

/*        if (d && _i < 1) {
            newProgressIndex = _i + _f;
            console.log('forward', _i, start, distance * newProgressIndex, distance);
            // Going forward and we're not in the last slide
            gsap.to(window, {
                scrollTo: {
                    y: `${start + distance === start+distance*newProgressIndex ? `${st!.end}` : start + distance * newProgressIndex}`,
                },
            });
            progressIndex.current = newProgressIndex;
        }
        if (!d && _i > 0) {
            newProgressIndex = _i - _f;
            console.log('back', _i, start, distance * newProgressIndex);
            // Going back and we're not in the first slide
            gsap.to(window, {
                scrollTo: {
                    // y: start + distance * newProgressIndex,
                    y: `${start === start+distance*newProgressIndex ? `${st!.start}` : start + distance * newProgressIndex}`,

                },
            });
            progressIndex.current = newProgressIndex;
        }
        if(!d && _i === 0){
            gsap.to(window, {
                scrollTo: {
                    // y: start + distance * newProgressIndex,
                    y: `${st!.start}`,

                },
            });
        }
        if(d && _i === 1){
            gsap.to(window, {
                scrollTo: {
                    // y: start + distance * newProgressIndex,
                    y: `${st!.end}`,

                },
            });
        }*/
        console.log("before condition");
        console.log('before (_i, _f, _i-_f)', _i, _f, ((_i)-(_f)));
    });

return (
    <div className="ShowcaseWrapperFirst snap-point" ref={innerWrapperRef}>
        <div className="ShowcaseBodyFirst"
             style={{
             width: `${items.length * 100}vw`,
            // width: '400vw',
            // height: `${(items.length - 1) * 100}vw`,

                 // width: `100vw`,
            // height: '300vh',
            }}
             >
            {/*<div className="innerElementShowcaseBody" ref={innerWrapperRef}>*/}

            {items.map((item, index) => (
                    <div className="inner" key={index} id={`${item.name}`}>
                        {index === 0 ? null : (
                            index === items.length - 1 ?
                                (
                                <button className="buttonLeftAlone" onClick={() => handleScroll(false)}>
                                    <svg className="leftArrow"
                                         height="100" viewBox="0 0 48 48" version="1"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M43 17.1 39.9 14 24 29.9 8.1 14 5 17.1 24 36z"/>
                                    </svg>
                                </button>
                                ) : (
                                <button className="buttonLeft" onClick={() => handleScroll(false)}>
                                    <svg className="leftArrow"
                                         height="100" viewBox="0 0 48 48" version="1"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M43 17.1 39.9 14 24 29.9 8.1 14 5 17.1 24 36z"/>
                                    </svg>
                                </button>
                                )
                        )}
                        <CardWith3DModel
                            modelUrl={item.modelUrl}
                            isItLastElement={index === items.length - 1}
                            modelImages={item.modelImages}
                            printingTime={item.printingTime}
                            modelName={item.name}
                            modelDemoImageUrl={item.modelDemoImage}
                            description={item.description}
                            secondPartDescription={item.secondPartDescription}
                            handleScroll={handleScroll}
                        />
                        {index === items.length - 1 ? null : (
                            index === 0 ?
                            (
                            <button className="buttonRightAlone" onClick={() => handleScroll(true)}>
                                <svg className="rightArrow"
                                     height="100" viewBox="0 0 48 48" version="1"
                                     xmlns="http://www.w3.org/2000/svg">
                                <path d="M43 17.1 39.9 14 24 29.9 8.1 14 5 17.1 24 36z"/>
                            </svg>
                            </button>
                            ): (
                            <button className="buttonRight" onClick={() => handleScroll(true)}>
                                <svg className="rightArrow"
                                     height="100" viewBox="0 0 48 48" version="1"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M43 17.1 39.9 14 24 29.9 8.1 14 5 17.1 24 36z"/>
                                </svg>
                            </button>
                                )
                        )
                        }
                    </div>
                ))}

            {/*</div>*/}
        </div>
        {isHeightFixed ? null : (
            <div className="ShowcaseBodyFirstFiller"></div>
        )}
    </div>
)
}

export default CardsRow;