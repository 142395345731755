import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faEnvelope, faCommentsDollar, faDna  } from './../../assets/Icons/SideMenuIcons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface SideMenuBarProp{
    handleClose: (callback?: () => void) => void,
    handleContactClick: () => void,
    handlePricingClick: () => void,
    handleAboutClick: () => void,
}

const SideMenuBar = ({handleClose, handleContactClick, handlePricingClick, handleAboutClick}: SideMenuBarProp) => {


    return (
        <div className="menuBar">
            <button className="cellWithinMenuBar" onClick={() => {
                handleClose(handleContactClick);

            }}>
                <FontAwesomeIcon className="icon" icon={faEnvelope as IconProp}/>
                <p>Kontakt</p>
            </button>
            <button className="cellWithinMenuBar" onClick={() => {
                handleClose(handlePricingClick);
            }}>
                <FontAwesomeIcon className="icon" icon={faCommentsDollar as IconProp}/>
                <p>Wycena</p>
            </button>
            <button className="cellWithinMenuBar" onClick={() => {
                handleClose(handleAboutClick);
            }}>
                <FontAwesomeIcon className="icon" icon={faDna as IconProp}/>
                <p>O mnie</p>
            </button>
        </div>
    );
}

export default SideMenuBar;