import "./ColorPalete.css";
import React, {useLayoutEffect} from 'react';
import gsap from "gsap";
import useIntersectionObserver from "../IntersectionObserver";


interface ColorPaleteProps{
    quantityOfBars: number;
    colors: string[];
}

const ColorPalete = ({quantityOfBars, colors}: ColorPaleteProps) => {
    const [isVisible, swatchesRef] = useIntersectionObserver({
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: 0.1,
        maintainObservation: false,
    });

    useLayoutEffect(() => {
        const innerSwatches = document.querySelectorAll('.innerSwatch') as  NodeListOf<HTMLElement>;
        const pageWrapper=document.querySelector(".ShowcaseWrapperSecond");
        innerSwatches.forEach((swatch, index) => {

        })

        let ctx = gsap.context(() => {
            let rotation = -60;
            innerSwatches.forEach((swatch, index) => {
                // console.log(index);
                // if(index > 1) {
                //     rotation = index % 2 === 0 ? rotation+25: rotation;
                // }
                if(rotation>0 && rotation<25){
                    rotation=15;
                }
                // const rotateValue =  index % 2 === 0 ? rotation : -1 * rotation;
                gsap.to(swatch, {
                    rotate: rotation,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: pageWrapper,
                        start: 'top bottom',
                        end: 'bottom bottom',
                        // markers: true,
                    },
                });

                rotation+=25;
            })
        }, );
        return () => ctx.revert();
    }, [isVisible]);

    return(
        <div className="swatches" ref={swatchesRef}>
            {isVisible && (colors.map((color, index) => (
                <div key={index} className="innerSwatch" style={{background:`${color}`}}></div>
                // className="singleSwatch" style={{background:`${color}`}}
                )))}
        </div>
    );
}

export default ColorPalete;