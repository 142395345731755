import React from 'react';
import "../../components/CarouselFirstPage/Header.css";

interface ArrowIconProps{
    idOfDivToScrollInto: string,
}

const ArrowIcon = ({idOfDivToScrollInto} : ArrowIconProps) => {

    const handleClick = () => {
        // console.log(idOfDivToScrollInto);
        const targetElement = document.querySelector(idOfDivToScrollInto);

        if(targetElement){
            targetElement.scrollIntoView({behavior:'smooth'});
        }

    }

    return (
        <button
            className="arrowIconHeader"
            onClick={handleClick}>
            <svg className="svgArrow" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                 viewBox="0 0 16 16">
                <path fillRule="evenodd"
                      d="m 10.14548,14.854036 a -0.5,0.5 0 0 0 0.708,0 l 4,-4 a -0.50063178,0.50063178 0 0 0 -0.708,-0.708 l -3.146,3.147 V 3.5000355 A -2.5,2.5 0 0 0 8.4994791,1.0000356 H 0.49947924 a -0.5,0.5 0 0 0 0,0.9999999 H 8.4994791 a -1.5,1.5 0 0 1 1.4999998,1.5 v 9.7930005 l -3.1459998,-3.147 a -0.50063178,0.50063178 0 0 0 -0.708,0.708 z"/>
            </svg>
        </button>
    );
};

export default ArrowIcon;
