import React, {useContext, useState} from 'react';
import {EstimateForm} from "./EstimateForm";
import {OutputTable} from "./OutputTable";
import Footer from "./Footer/Footer";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./EstimationPage.css";
import {Context} from "../Context";


interface FormData{
    price:number;
    weight:number;
    xDimension:number;
    yDimension:number;
    zDimension:number;
}
const EstimationWrapper = () => {
    const [formData, setFormData] = useState<FormData | null>(null);
    const [showTable, setShowTable] = useState<boolean>(false);
    const [fileUrl, setFileUrl] = useState<string | null>(null);

    const { isWidthGreaterThanHeight} = useContext(Context);
    //const isWidthGreaterThanHeight = clientWidth > clientHeight;

    return (
        <div className="EstimationPage snap-point">
            <div className="EstimationPageBody" id="PricingWrapper">

                <EstimateForm setFormData={setFormData} setShowTable={setShowTable} setFileUrl={setFileUrl}/>
                {showTable && formData && <OutputTable formData={formData} fileUrl={fileUrl}/>}
                <div className={`BeforeOutputTableInfo ${showTable ? 'hidden' : 'active'}`}><p>Przeslij formularz,
                    aby wyświetlić szacowane koszty oraz sam model</p></div>
                {isWidthGreaterThanHeight ? (
                <div className="EstimationInformation">
                    <ul>
                        <li>Kalkulator podaje przybliżoną cenę, która może być nieco zawyżona.</li>
                        <li>W celu otrzymania dokładnej i&nbsp;indywidualnej wyceny zapraszam do kontaktu – możesz
                            skorzystać z&nbsp;formularza na poprzedniej stronie, napisać bezpośrednio na e-mail:
                            <a href="mailto: kontakt@flipdruk.pl"> kontakt@flipdruk.pl </a>
                            lub zadzwonić pod numer: <b>733&nbsp;359&nbsp;559</b>.
                        </li>
                    </ul>
                </div>
                ) : null}
            </div>


            <Footer/>
        </div>
    );
}

export default EstimationWrapper;