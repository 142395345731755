import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';


const MapWrapper = () => {
    const [consent, setConsent] = useState(false);

    // Check for existing consent cookie when the component mounts
    useEffect(() => {
        const existingConsent = Cookies.get('cookie_consent');
        if (existingConsent) {
            setConsent(true);
        }
    }, []);

    const handleConsent = () => {
        // Set the cookie with an expiration of 30 days
        Cookies.set('cookie_consent', 'true', { expires: 30 });
        setConsent(true);
    };

    return (
        <div className="MapWrapper">
            {!consent && (
                <div className="cookie-consent">
                    <p>
                        Aby użyć mapy Google, zaakceptuj fakt, że korzysta ona z plików Cookies.
                        {/*We use Google Maps on this website which may set cookies. Please accept to view the map.*/}
                    </p>
                    <button onClick={handleConsent}>Akceptuj</button>
                </div>
            )}
            {consent && (
                <iframe
                    title="Localization"
                    className="IframeMap"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2517.04563821043!2d20.63765907771077!3d50.88586567167852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471827c7841ad377%3A0xfb5e52b9164fb09!2sBukowa%2010%2C%2025-001%20Kielce!5e0!3m2!1spl!2spl!4v1727173610448!5m2!1spl!2spl"
                    allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            )}
        </div>
    );
}

export default MapWrapper;
