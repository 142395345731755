import React, {useContext, useState} from 'react';
import {Offcanvas} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBars} from "@fortawesome/free-solid-svg-icons";
import "../../App.css";
import "./SideMenu.css";
import SideMenuBar from "./SideMenuBar";
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {Context} from "../Context";

import {faBriefcase, faBellConcierge, faCalculator, faEnvelope, faCircleUser  } from './../../assets/Icons/SideMenuIcons';


import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);


const SideMenu : React.FC = () => {
    const [show, setShow] = useState(false);
    const { isWidthGreaterThanHeight} = useContext(Context);
    const [ afterCloseAction, setAfterCloseAction ] = useState<(() => void) | null>(null);
    //const screenWiderThanHigher = clientWidth > clientHeight;

    const handleClose = (callback?: () => void) => {
        if(!isWidthGreaterThanHeight && callback) {
            setAfterCloseAction(() => callback);
            setShow(false);
            // document.documentElement.style.setProperty('overflow-y', "scroll");
        }else if(!isWidthGreaterThanHeight && !callback){
            setShow(false);
        }
        else if(isWidthGreaterThanHeight && callback){
            callback();
        }else if(isWidthGreaterThanHeight && !callback){
            setShow(false);
        }
    }
    const handleShow = () => {
        setShow(true);
        document.documentElement.style.setProperty('overflow-y', "hidden");
    }

    function handleContactClick(){
        console.log("jestem w handle contact click");
        const elementId = isWidthGreaterThanHeight ? "ColorSwatchesAndContactWrapper" : "contact";
        const target = document.getElementById(elementId);
        console.log(target);
        if(target) {
            gsap.to(window, {
                duration: 0,
                scrollTo: target,
            })
        }
    }
    function handlePricingClick(){
        const target = document.getElementById("PricingWrapper");
        console.log(target);

        if(target) {
            gsap.to(window, {
                duration: 0,
                scrollTo: target,
            })
        }
    }
    function handleAboutClick(){
        const target = document.getElementById("aboutMe");
        console.log(target);
        if(target) {
            gsap.to(window, {
                duration: 0,
                scrollTo: target,
            })
        }
    }

    return(
        <div className="SideMenuWrapper">
            {/*<Button
                variant="primary"
                onClick={handleShow}
                className="hamburger-icon">
                <FontAwesomeIcon icon={faBars} style={{width: '100%', height: 'auto'}}/>
            </Button>*/}

            <div className="HamburgerMenuWrapper">
                <div className="StickyWrapper">
                    <button
                        onClick={handleShow}
                        className="hamburger-icon"
                    >
                        <FontAwesomeIcon icon={faBars as IconProp} style={{width: '100%', height: 'auto'}}/>
                    </button>
                    {isWidthGreaterThanHeight &&
                    <SideMenuBar
                        handleClose={handleClose}
                        handleContactClick={handleContactClick}
                        handlePricingClick={handlePricingClick}
                        handleAboutClick={handleAboutClick}
                    />
                    }
                </div>
            </div>

            <Offcanvas
                show={show}
                onHide={handleClose}
                scroll={true}
                style={{height:"100svh"}}
                onExited={() => {
                    document.documentElement.style.setProperty('overflow-y', "scroll");
                    if(afterCloseAction) {
                        requestAnimationFrame(() => {
                        afterCloseAction();
                        setAfterCloseAction(null);
                        })
                    }
                }}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="offcanvas-title">
                        {!isWidthGreaterThanHeight &&
                        <SideMenuBar
                            handleClose={handleClose}
                            handleContactClick={handleContactClick}
                            handlePricingClick={handlePricingClick}
                            handleAboutClick={handleAboutClick}
                        />}
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="offcanvas-body">
                    <div className="bodyText">
                        <ul className="unorderedList">
                            <li className="title">O mnie <FontAwesomeIcon className="icon" icon={faCircleUser as IconProp}/>
                            </li>
                            <ul>
                                <li>Mam na imię Filip i&nbsp;oferuję usługi druku 3D z Kielc.</li>
                            </ul>
                            <li className="title">Kontakt <FontAwesomeIcon className="icon" icon={faEnvelope as IconProp}/>
                            </li>
                            <ul>
                                <li>Aby się ze mną skontaktować, przejdź do zakładki 'Kontakt' i&nbsp;wyślij wiadomość e&#8209;mail.</li>
                            </ul>
                            <li className="title">Usługi <FontAwesomeIcon className="icon" icon={faBellConcierge as IconProp}/>
                            </li>
                            <ul>
                                <li>Każde zamówienie realizuję osobiście przez e&#8209;mail, aby zapewnić Ci w pełni spersonalizowaną obsługę.</li>
                                <li>Potrzebujesz unikalnego projektu? Zaprojektuję i&nbsp;wydrukuję go dla Ciebie —&nbsp;napisz do mnie!</li>
                            </ul>
                            <li className="title">Kalkulator kosztów <FontAwesomeIcon className="icon" icon={faCalculator as IconProp}/>
                            </li>
                            <ul>
                                <li>Kalkulator podaje przybliżone ceny i&nbsp;nie stanowi ostatecznej wyceny.</li>
                                <li>W celu precyzyjnej wyceny dołącz do e&#8209;maila model 3D, który chcesz wydrukować.</li>
                            </ul>
                            <li className="title">Portfolio <FontAwesomeIcon className="icon" icon={faBriefcase as IconProp}/>
                            </li>
                            <ul>
                                <li>Zobacz moje prace w interaktywnej przeglądarce modeli 3D i&nbsp;przekonaj się o&nbsp;jakości moich usług.</li>
                            </ul>
                        </ul>
                    </div>


                    {/*<CardGroup>
                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="holder.js/100px180" />
                            <Card.Body>
                                <Card.Title>Card Title</Card.Title>
                                <Card.Text>
                                    <p>Wszystkie zamówienia realizuję indywidualnie poprzez kontakt mailowy, aby zapewnić Ci w pełni spersonalizowaną obsługę dostosowaną do Twoich potrzeb.</p>
                                    <p>Aby skontaktować się przejdź do ekranu kontaktowego, gdzie wyślesz do mnie email. </p>
                                </Card.Text>
                                <button className="primary">Go somewhere</button>
                            </Card.Body>
                        </Card>

                        <Card style={{ width: '18rem' }}>
                            <Card.Img variant="top" src="holder.js/100px180" />
                            <Card.Body>
                                <Card.Title>Card Title</Card.Title>
                                <Card.Text>
                                    Some quick example text to build on the card title and make up the
                                    bulk of the card's content.
                                </Card.Text>
                                <Card.Link href="#">Card Link</Card.Link>
                                <Card.Link href="#">Another Link</Card.Link>
                            </Card.Body>
                        </Card>

                    </CardGroup>*/}
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
}

export default SideMenu;