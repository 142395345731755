import React, { useState } from "react";
import "./EstimationPage.css";

interface FormData {
    price: number;
    weight: number;
    xDimension: number;
    yDimension: number;
    zDimension: number;
}
interface EstimateFormProps{
    setFormData: React.Dispatch<React.SetStateAction<FormData | null>>;
    setShowTable: React.Dispatch<React.SetStateAction<boolean>>;
    setFileUrl: React.Dispatch<React.SetStateAction<string | null>>;
}

export function EstimateForm({setFormData, setShowTable, setFileUrl} : EstimateFormProps) {
    const [file, setFile] = useState<File | null>(null); // File type or null

    const [shouldBeVisible, setShouldBeVisible] = useState<boolean>(true);
    const [fileName, setFileName] = useState<string>('Kliknij tutaj, aby wgrać plik');
    const [isFileSelected, setIsFileSelected] = useState<boolean>(false);

    const [material, setMaterial] = useState<string>(""); // Default string
    const [postProcessing, setPostProcessing] = useState<string>("");
    const [quality, setQuality] = useState<string>("");
    const [filling, setFilling] = useState<string>("");
    const [executionTime, setExecutionTime] = useState<string>("");
    const [quantity, setQuantity] = useState<number>(1); // Number type, default is 1

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        //setFile(event.target.files[0]);
        event.preventDefault();
        const file = event.target.files?.[0];
        if (file) {
            setFile(file);
            const url = URL.createObjectURL(file);
            setFileUrl(url);  // Create a blob URL and store it
            setFileName(file.name);
            setIsFileSelected(true);
            console.log(`Preview URL: ${url}`);
        }else{
            setFileName('Kliknij tutaj, aby wgrać plik');
            setIsFileSelected(false);
        }
    };

    const incrementQuantity = () => setQuantity((prev) => prev + 1);
    const decrementQuantity = () => {
        if (quantity > 1) {
            setQuantity((prev) => prev - 1);
        }
    };

    const handleVisibility = () => {
        /*OPOZNIC EFEKT ZMAINY Z_INDEX*/
        /*const formContainerWrapper = document.querySelector('.formContainerWrapper') as HTMLDivElement;
        if (formContainerWrapper) {
            formContainerWrapper.style.zIndex = shouldBeVisible ? '1' : '3'; // Adjust as necessary
        }*/

        setShouldBeVisible(!shouldBeVisible);

        /*const formContainerWrapper = document.querySelector('.formContainerWrapper') as HTMLDivElement;
        if (formContainerWrapper) {
            formContainerWrapper.style.zIndex = shouldBeVisible ? '0' : '1'; // Adjust as necessary
        }*/
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!file) {
            console.error("No file selected.");
            return;
        }

        // Create a FormData object
        const formData = new window.FormData();
        /*formData.append('file', file);
        formData.append('materialId', parseInt(material, 10));
        formData.append('infillPercentage', parseInt(filling, 10));
        formData.append('surfaceThickness', 0.05);
        formData.append('unit', 'mm');
        formData.append('postProcessing', postProcessing);
        formData.append('executionTime', executionTime);
        formData.append('quantity', parseInt(quantity, 10));
        formData.append('quality', quality);*/

        formData.append('file', file);
        formData.append('materialId', material);
        formData.append('infillPercentage', filling);
        formData.append('surfaceThickness', "0.05");
        formData.append('unit', 'mm');
        formData.append('postProcessing', postProcessing);
        formData.append('executionTime', executionTime);
        formData.append('quantity', quantity.toString());
        formData.append('quality', quality);

        try{
            const response = await fetch('/api/upload', {
            //const response = await fetch('http://localhost:8080/api/upload', {
               method:'POST',
               body: formData
            });

            if(!response.ok){
                throw new Error("Error while trying to get data from API");
            }

            const data = await response.json();
            console.log("Success: ", data);
            setFormData(data);
            setShouldBeVisible(false);
            setShowTable(true);
            }catch(error){
            console.log("Error: ", error);
            setShowTable(false);
        }
    };

    return (
        <div className={`formContainerWrapper ${shouldBeVisible ? 'active' : 'hidden'}`} tabIndex={-1} id="estimation">
            {/*<button className={`VisibilityButton ${shouldBeVisible? 'active' : 'hidden'}`} onClick={handleVisibility}>
                <FontAwesomeIcon icon={faCaretUp} className="VisibilityButtonIcon"/>
            </button>*/}
            <form onSubmit={handleSubmit} className= {`formContainer ${shouldBeVisible? 'active' : 'hidden'}`}>
                <div className="inputGroup">
                    {/*<link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css"/>*/}

                    {/*Optional text here

                    <label htmlFor="inputField" className="btn btn-info">Try me</label>
                    <input type="file" id="inputField" style={{display:'none'}}/>*/}
                    <div className="FirstRowWithButton">
                        <label>Prześlij plik:</label>
                        <button className={`VisibilityButtonTest ${shouldBeVisible ? 'active' : 'hidden'}`}
                                onClick={handleVisibility}
                                type="button">
                            <svg className={`collapsingArrow ${shouldBeVisible ? 'active' : 'hidden'}`} viewBox="0 0 48 48" version="1"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fill="#2196F3" d="M43 17.1 39.9 14 24 29.9 8.1 14 5 17.1 24 36z"/>
                            </svg>
                        </button>
                    </div>
                    <input type="file" id="inputField" style={{display: 'none'}} onChange={handleFileChange}
                           className="inputField"/>
                    <label htmlFor="inputField" className={`uploadFileInput ${isFileSelected ? 'file-selected' : ''}`}>{fileName}</label>
                </div>
                <div className="inputGroup">
                    <label>Materiał:</label>
                    <select value={material} onChange={(e) => setMaterial(e.target.value)} className="inputField">
                        <option value="">Wybierz materiał</option>
                        <option value="1">PLA</option>
                        <option value="2">ABS</option>
                    </select>
                </div>
                <div className="inputGroup">
                    <label>Obróbka końcowa:</label>
                    <select
                        value={postProcessing}
                        onChange={(e) => setPostProcessing(e.target.value)}
                        className="inputField"
                    >
                        <option value="">wybierz rodzaj obróbki końcowej</option>
                        <option value="without post-processing">
                            bez obróbki
                        </option>
                        <option value="deleting supports">usuwanie podpórek</option>
                        <option value="grinding">szlifowanie</option>
                    </select>
                </div>
                <div className="inputGroup">
                    <label>Jakość:</label>
                    <select value={quality} onChange={(e) => setQuality(e.target.value)} className="inputField">
                        <option value="">Wybierz jakość</option>
                        <option value="standard">standard</option>
                        <option value="high">wysoka</option>
                        <option value="thick layer">grube warstwy</option>
                    </select>
                </div>
                <div className="inputGroup">
                    <label>Wypełnienie w %:</label>
                    <select value={`${filling}%`} onChange={(e) => setFilling(e.target.value.replace('%', ''))} className="inputField">
                        <option value="">Select % of Filling</option>
                        <option value="5%">5%</option>
                        <option value="15%">15%</option>
                        <option value="25%">25%</option>
                        <option value="50%">50%</option>
                    </select>
                </div>
                <div className="inputGroup">
                    <label>Czas realizacji:</label>
                    <select
                        value={executionTime}
                        onChange={(e) => setExecutionTime(e.target.value)}
                        className="inputField"
                    >
                        <option value="">Wybierz czas realizacji</option>
                        <option value="standard (3-4 days)">standardowy (3-4 dni)</option>
                        <option value="express (1-2 days)">ekspresowy (1-2 dni)</option>
                        <option value="loose (5-8 days)">luźny (5-8 dni)</option>
                    </select>
                </div>
                <div className="quantityControl">
                    <label>Ilość:</label>
                    <button type="button" onClick={decrementQuantity} className="button">
                        -
                    </button>
                    <span>{quantity}</span>
                    <button type="button" onClick={incrementQuantity} className="button">
                        +
                    </button>
                </div>
                {/*<div className="submitButton">*/}
                    <button type="submit" className="submitButtonEstimation">Prześlij</button>
                {/*</div>*/}
            </form>
        </div>
    );
}
