import React, {useCallback, useContext, useLayoutEffect, useRef} from "react";
import "./CardWith3DModel.css";
import ModelWrapperWithLoader from './ModelWrapperWithLoader';
import {Context} from "../Context";
import useIntersectionObserver from "../IntersectionObserver";
import CarouselForModelImages from "./CarouselForModelImages";
import debounce from "../../assets/functions/debounce";

interface CardWith3DModelProps{
    modelUrl: string;
    isItLastElement?: boolean;
    modelImages: Record<string, string>;
    modelDemoImageUrl: string;
    printingTime: string;
    description: string;
    secondPartDescription: string;
    modelName: string;
    handleScroll: (arg: boolean) => void;
}
const CardWith3DModel = ({modelUrl, isItLastElement = false, modelImages, modelDemoImageUrl, printingTime, description, secondPartDescription, modelName, handleScroll}: CardWith3DModelProps) => {
    const [isVisible, wrapperRef] = useIntersectionObserver({
        root: null,
        rootMargin: "0px",
        threshold: 0.01,
        maintainObservation: false,
    });
    const { isWidthGreaterThanHeight } = useContext(Context);
    const startX = useRef<number>(0);
    const isTouching = useRef(false);
    const fontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);

    // console.log("window.innerWidth, window.innerHeight")
    // console.log(window.innerWidth, window.innerHeight)


    //const isWidthGreaterThanHeight = clientWidth > clientHeight;
    const debouncedHandleScroll = useCallback(
        debounce((direction: boolean) => handleScroll(direction), 250),
        [handleScroll]
    );

    const isCardIncluded = useCallback((target: EventTarget | null): boolean => {
        const element = target as HTMLElement;
        return !!(element.closest(".ModelWrapperWithLoaderMain") || element.closest(".ImageCardWrapper"));
    }, []);

    /*useLayoutEffect(() => {
        const cards = document.querySelectorAll('.CardWrapper') as  NodeListOf<HTMLElement>;
        const THRESHOLD = 100;


        const handleMouseMove = (e: MouseEvent) => {
            const { clientX, clientY, currentTarget, target } = e;
            const card = currentTarget as HTMLElement;
            //const cardTarget = target as HTMLElement;

            //console.log(`Target: ${cardTarget.getAttribute('class')}`);
            //console.log(`Current Target: ${card.getAttribute('class')}`);
            //const isModelClicked = cardTarget.closest('.ModelWrapperWithLoaderMain');
            //const isImageAndDescriptionClicked = cardTarget.closest('.ImageCardWrapper');
            //console.log(`isModelClicked: ${cardTarget.closest('.ModelWrapperWithLoaderMain')}`);
            //console.log(`isModelClicked: ${isModelClicked}`);


            // if(!cardTarget.classList.contains("ModelWrapperWithLoaderMain"))
            //if(!isModelClicked && !isImageAndDescriptionClicked){
            if(!isCardIncluded(target)){
                console.log("przeszlo sprawdzenie");

                // if(card.classList.contains("card-mouse-leaving-transition")){
                //     setTimeout(() => {
                //         card.classList.remove('card-mouse-leaving-transition');
                //     }, 300);
                // }



                // console.log(clientX, clientY);

                // Extract dimensions and offsets
                const { clientWidth, clientHeight, offsetLeft, offsetTop } = card;

                // Calculate horizontal and vertical positions relative to the card
                const horizontal = (clientX - offsetLeft) / clientWidth;
                const vertical = (clientY - offsetTop) / clientHeight;
                console.log(horizontal, vertical);

                // Rotation thresholds
                const THRESHOLD = 5; // You can adjust this threshold value as needed

                // Calculate rotation values for X and Y axes
                const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
                const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

                console.log(`: ${rotateX} - rotateX`);
                console.log(`: ${rotateY} - rotateY`);
                console.log(`: ${clientWidth} - clientWidth`);

                // Apply the transformation
                card.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
            }else{
                card.style.transform = `perspective(${card.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
            }
        }

        const handleMouseLeave = (e: MouseEvent) => {
            const {currentTarget} = e;
            const card = currentTarget as HTMLElement;
            // card.classList.add("card-mouse-leaving-transition");
            card.style.transform = `perspective(${card.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
            /!*setTimeout(() => {
                card.classList.remove('card-mouse-leaving-transition');
            }, 300);*!/
        }

        cards.forEach((card) => {
            card.addEventListener('mousemove', handleMouseMove);
            card.addEventListener('mouseleave', handleMouseLeave);
            //card.addEventListener('touchstart', handleTouchStart);
            //card.addEventListener('touchend', handleTouchEnd);
        });

        return () => {
            cards.forEach((card) => {
                card.removeEventListener('mousemove', handleMouseMove);
                card.removeEventListener('mouseleave', handleMouseLeave);
                //card.removeEventListener('touchstart', handleTouchStart);
                //card.removeEventListener('touchend', handleTouchEnd);
            });
        };
    }, []);*/

    const handleMouseMove = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const { clientX, clientY, currentTarget, target } = e;
        const card = currentTarget;
        if(!isCardIncluded(target)){
            const { clientWidth, clientHeight, offsetLeft, offsetTop } = card;

            // Calculate horizontal and vertical positions relative to the card
            const horizontal = (clientX - offsetLeft) / clientWidth;
            const vertical = (clientY - offsetTop) / clientHeight;
            // console.log(horizontal, vertical);

            // Rotation thresholds
            const THRESHOLD = 5; // You can adjust this threshold value as needed

            // Calculate rotation values for X and Y axes
            const rotateX = (THRESHOLD / 2 - horizontal * THRESHOLD).toFixed(2);
            const rotateY = (vertical * THRESHOLD - THRESHOLD / 2).toFixed(2);

            // console.log(`: ${rotateX} - rotateX`);
            // console.log(`: ${rotateY} - rotateY`);
            // console.log(`: ${clientWidth} - clientWidth`);

            // Apply the transformation
            card.style.transform = `perspective(${clientWidth}px) rotateX(${rotateY}deg) rotateY(${rotateX}deg) scale3d(1, 1, 1)`;
        }else{
            card.style.transform = `perspective(${card.clientWidth}px) rotateX(0deg) rotateY(0deg)`;
        }
    }, []);

    const handleMouseLeave = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const card = e.currentTarget;
        card.style.transform = `perspective(${card.clientWidth}px) rotateX(0deg) rotateY(0deg)`;


    }, []);

    const handleTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
        const {target} = e;
        if(isCardIncluded(target)) return

        startX.current = e.touches[0].clientX;
        isTouching.current = true;
    },[]);

    const handleTouchEnd = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
        if (!isTouching.current) return; // Only proceed if a touch event was active

        isTouching.current = false;


        const targetElement = e.target as HTMLElement;
        // console.log("Touched element:", targetElement);

        const endX = e.changedTouches[0].clientX;
        const deltaX = startX.current - endX;

        if(Math.abs(deltaX) > 1.5 * fontSize){
            debouncedHandleScroll(deltaX > 0);
        }
    },[])

    const handleMouseDown = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if (isCardIncluded(e.target)) return;

        startX.current = e.clientX;
        isTouching.current = true;
    }, []);

    const handleMouseUp = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        if (!isTouching.current) return;

        isTouching.current = false;
        const endX = e.clientX;
        const deltaX = startX.current - endX;

        if (Math.abs(deltaX) > 1.5 * fontSize) {
            handleScroll(deltaX > 0);
        }
    }, [handleScroll, fontSize]);




    return(
        <div className="CardWrapper"
             ref={wrapperRef}
             id={modelName}
             onMouseMove={handleMouseMove}
             onMouseLeave={handleMouseLeave}
             onTouchStart={handleTouchStart}
             onTouchEnd={handleTouchEnd}
            onMouseDown={handleMouseDown}
            onMouseUp={handleMouseUp}
        >

            {isVisible && (
                <>
                    <ModelWrapperWithLoader modelUrl={modelUrl} modelDemoImageUrl={modelDemoImageUrl}/>
                    <div className="ImageCardWrapper">
                        {isWidthGreaterThanHeight ? (
                            <>
                                <div className="textNextToImage">
                                    <p><strong>{modelName}</strong></p>
                                    <p>{description}</p>
                                    <p>{secondPartDescription}</p>
                                    <p>Czas drukowania: {printingTime}</p>
                                </div>
                                {/*<div className="CarouselForModelImagesWrapper"></div>*/}
                                <CarouselForModelImages modelImages={modelImages}/>
                                {/*<img loading="lazy" src={modelImages[0]} alt="Cupholder"/>*/}
                            </>
                            ) :
                            (
                                // <></>
                                <CarouselForModelImages modelImages={modelImages}/>
                                // <img loading="lazy" src={modelImage} alt="Cupholder"/>
                            )}
                    </div>
                </>
            )}
        </div>
    )
}

export default CardWith3DModel;

