import React, {useContext, useState} from 'react';
import Image from 'react-bootstrap/Image';


import firstImage from "../../assets/Images/carouselImage/first___.jpg";
import secondImage from "../../assets/Images/carouselImage/second.jpg";
import thirdImage from "../../assets/Images/carouselImage/third.jpg";
import thirdImageSmall from "../../assets/Images/carouselImage/third_.png";
import firstImageSmall from "../../assets/Images/carouselImage/first_____.jpg";

// import images from './CarouselImagesImports';



// type QualityKey = 'mobile_high_quality' | 'mobile_low_quality' | 'desktop_high_quality' | 'desktop_low_quality' | null;

/*const getImageSrc = (size: string, resolution: string, src: string): string | undefined => {
    const key = `${size}_${resolution}_quality` as QualityKey;
    if(key != null) {
        return images[key]?.[src];
    }
    return images["desktop_high_quality"][1];
};*/

interface CarouselImageProps {
    text: string;
    src: string;
}


const CarouselImage : React.FC<CarouselImageProps> = ({ text, src }) => {
    //const { clientHeight, clientWidth } = useContext(Context);
    // const imageUrl = (clientWidth > 500 ? imageMap[src] : imageMap[src+"Small"]) || "https://images.unsplash.com/photo-1518732714860-b62714ce0c59?q=80&w=1170&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";


    // console.log("images.desktop_low_quality[1]");
    // console.log(images.desktop_low_quality[1]);
    /*
        const [isVisible, imageRef] = useIntersectionObserver({
            root: null,
            rootMargin: "0px",
            threshold: 0.1,
            maintainObservation: false,
        });*/

    return (
       /* <div style={{
            width:'100%',
            height:'100%'
        }}
        ref={imageRef}>
            {isVisible && (*/
        <Image
            fluid
            src={src}
            // src={`images[${splitSizeAndResolution[0]}_${splitSizeAndResolution[1]}_quality][src]`}
            alt={text}
            className="carousel-single-image"
            loading="lazy"
        />
            /*)}
        </div>*/
    );
}

export default CarouselImage;
