import React, { useEffect, useState, ReactNode } from 'react';

export type ContextProps = {
    clientHeight: number;
    clientWidth: number;
    isWidthGreaterThanHeight: boolean;
};

export const Context = React.createContext<ContextProps>({
    clientHeight: 0,
    clientWidth: 0,
    isWidthGreaterThanHeight: false,
});

type ContextProviderProps = {
    children: ReactNode;
};

export const ContextProvider: React.FC<ContextProviderProps> = ({ children }) => {
    const [clientHeight, setClientHeight] = useState<number>(0);
    const [clientWidth, setClientWidth] = useState<number>(0);

    const isWidthGreaterThanHeight = clientWidth > clientHeight;

    useEffect(() => {
        const getVh = () => {
            return Math.max(
                document.documentElement.clientHeight || 0,
                window.innerHeight || 0
            );
        };

        const getVw = () => {
            return Math.max(
                document.documentElement.clientWidth || 0,
                window.innerWidth || 0
            );
        };

        const handleResize = () => {
            setClientHeight(getVh());
            setClientWidth(getVw());
        };

        // Initial call to set the values
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Context.Provider value={{ clientHeight, clientWidth, isWidthGreaterThanHeight}}>
            {children}
        </Context.Provider>
    );
};
