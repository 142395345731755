import React, {useState, useEffect, useRef} from "react";
import Fade from "./Fade";
import Loader from "./Loader";
import ModelRenderer from "./ModelRenderer";
import "./ModelWrapperWithLoader.css";

interface ModelWrapperWithLoaderProps{
    modelUrl: string;
    modelDemoImageUrl: string;
}
const ModelWrapperWithLoader = ({modelUrl, modelDemoImageUrl}: ModelWrapperWithLoaderProps) => {
    const [show, setShow] = useState(true);
    const [isModelLoaded, setIsModelLoaded] = useState(false);
    const [shouldModelBeLoaded, setShouldModelBeLoaded] = useState(false);
    const iconRef = useRef<HTMLElement>(null);

    useEffect(() => {
        if(isModelLoaded){
            setShow(false);

        }
    }, [isModelLoaded])

    const handleLoading = () => {
        if(iconRef.current) {
            iconRef.current.classList.add("animate");
        }
        setTimeout(() => {
            setShouldModelBeLoaded(true)
        }, 500);

    }

    return (
        <div className="ModelWrapperWithLoaderMain">
            {/*<button onClick={() => {
                setShow(show => !show)
                setIsModelLoaded(isModelLoaded => !isModelLoaded);
            }
            }>
                {show ? "show" : "hide"}
            </button>*/}
            {!shouldModelBeLoaded ?
                <button  className="buttonToOpenModel" onClick={() => handleLoading()}>
                    <img loading="lazy" src={modelDemoImageUrl} alt={modelDemoImageUrl}/>
                    <i ref={iconRef} className="bi bi-arrows-move"></i>
                </button>
                :


                <>
                <Fade show={show}>
                    <Loader/>
                </Fade>
                <div className={`modelRendererMain ${isModelLoaded ? 'fadein' : 'fadeout'}`}>
                    <ModelRenderer modelUrl={modelUrl} readyFunction={setIsModelLoaded}/>
                </div>
            </>
            }
        </div>
    );
}

export default ModelWrapperWithLoader;