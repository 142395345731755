import React from 'react';
import { StlViewer } from "react-stl-viewer";
import "./EstimationPage.css";

interface FormData{
    price:number;
    weight:number;
    xDimension:number;
    yDimension:number;
    zDimension:number;
}

interface OutputTableProps{
    formData: FormData | null;
    fileUrl: string | null;
}

export function OutputTable({formData, fileUrl} : OutputTableProps) {
    if (!formData) {
        return <p>No form data available.</p>;  // Handle null case
    }

    return (
        <div className="OutputWrapper">
            <div className="formContainerOutput">
                {/*<div className="FormOpenerWrapper">
                    <button className="FormOpenerButton">
                        <FontAwesomeIcon icon={faTableList} className="FormOpenerButtonIcon"/>
                    </button>
                </div>*/}

                <form>
                    <div className="dataDisplay">
                        <label>Koszt:</label>
                        <div className="dataValue">{formData.price.toFixed(2)} pln</div>
                    </div>
                    <div className="dataDisplay">
                        <label>Waga obiektu:</label>
                        <div className="dataValue">{formData.weight.toFixed(2)} g</div>
                    </div>
                    <div className="dataDisplay">
                        <label>Wymiary:</label>
                        <div
                            className="dataValue">{formData.xDimension}x{formData.yDimension}x{formData.zDimension} mm
                        </div>
                    </div>
                </form>
            </div>


            <div className="viewerContainer">
                {fileUrl ?
                    <StlViewer
                        // onFinishLoading={stopLoadingAnimation}
                               style={{width: '100%', height: '100%'}} orbitControls shadows url={fileUrl}/>
                    : <p>Error while uploading file</p>}
            </div>

        </div>
    );
}
