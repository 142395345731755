import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './fonts.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import App from './App';
import reportWebVitals from './reportWebVitals';
import {IsMobileProvider} from "./components/IsMobileContext";
import {ContextProvider} from "./components/Context";


const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
    <React.StrictMode>
        <IsMobileProvider>
            <ContextProvider>
            <div className="main-container">
                <App />
            </div>
            </ContextProvider>
        </IsMobileProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
