import React from "react";

interface faq{
    question: string,
    answer: string,
}
interface AccordionItemProp{
    faq: faq,
    index: number,
    isActive: boolean,
    onClick: (arg: number) => void,
}
function AccordionItem({ faq, index, isActive, onClick }: AccordionItemProp) {
    const renderWithNonBreakingSpaces = (text: string) => {
        return text.split(' ').map((word, idx) => {
            if (word.length === 1) { // If it's a single letter like "i"
                return (
                    <span key={idx}>
            {word}
                        {String.fromCharCode(160)}{/* Add non-breaking space after */}
          </span>
                );
            }
            return word + ' ';
        });
    };
    return (
        <li>
            <div className="question"
                onClick={() => onClick(index)}
            >
                {renderWithNonBreakingSpaces(faq.question)}
            </div>
                <div className={`content ${isActive ? "mount" : "unmount"}`}>
                    <p>{renderWithNonBreakingSpaces(faq.answer)}</p>
                </div>
        </li>
    );
}

export default AccordionItem;
