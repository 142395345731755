import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import {Canvas, useFrame, useLoader, useThree} from '@react-three/fiber';
import {OrbitControls, PerspectiveCamera, Center, useFBO} from '@react-three/drei';
import { OrbitControls as OrbitControlsType } from 'three-stdlib'
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { Html } from '@react-three/drei';

class ErrorBoundary extends React.Component<
    { children: React.ReactNode },
    { hasError: boolean }
> {
    constructor(props: { children: React.ReactNode }) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(_: Error) {
        return { hasError: true };
    }

    render() {
        if (this.state.hasError) {
            return (
                <Html center>
                    <div style={{ color: 'red', fontSize: '5rem' }}>
                        Error loading model
                    </div>
                </Html>
            );
        }

        return this.props.children;
    }
}


interface ModelDimensions{
    width: number;
    height: number;
    depth: number;
    center: THREE.Vector3;
}
interface ModelProps {
    url: string;
    onModelLoad?: (dimensions: ModelDimensions) => void;
}

const Model: FC<ModelProps> = ({ url, onModelLoad }) => {
    const geometry = useLoader(STLLoader, url);
    const meshRef = React.useRef<THREE.Mesh>(null);
    // const groupRef = useRef<THREE.Group>(null);

    useEffect(() => {
        if (geometry) {
            // Compute the bounding box
            const box = new THREE.Box3().setFromObject(new THREE.Mesh(geometry));
            const center = box.getCenter(new THREE.Vector3());
            const size = box.getSize(new THREE.Vector3());

            // Log dimensions
            const dimensions = {
                width: size.x,
                height: size.y,
                depth: size.z,
                center: center,
            };

            console.log('Model dimensions:', dimensions);
            onModelLoad?.(dimensions);

            // geometry.translate(-size.x / 2, 0, 0); // Przesuwa geometry o połowę szerokości
            /*if(groupRef.current) {
                groupRef.current.position.set(0, -size.y / 2, 0);
            }*/
        }
    }, [geometry, onModelLoad]);

    /*useFrame(() => {
        if (groupRef.current) {
            groupRef.current.rotation.y += 0.0105; // Animacja obrotu grupy
        }
    });*/

    return (
        <Center>
                <mesh ref={meshRef} geometry={geometry} scale={1}
                      rotation={[-Math.PI / 2, 0, 0]}> {/*castShadow receiveShadow*/}
                    <meshStandardMaterial color="#C0C0C0"/>
                </mesh>
        </Center>
    );
};

const Snapshot = React.forwardRef((props, ref) => {
    const { gl, scene, camera } = useThree();

    // Define the snapshot function
    const takeSnapshot = () => {
        gl.setSize(800, 600); // Optional: Set desired resolution
        gl.render(scene, camera);
        const snapshotDataURL = gl.domElement.toDataURL('image/png');

        // Download the image
        const link = document.createElement('a');
        link.href = snapshotDataURL;
        link.download = 'scene-snapshot.png';
        link.click();
    };

    // Assign the takeSnapshot function to the ref
    React.useImperativeHandle(ref, () => takeSnapshot);

    return null; // This component does not render anything
});

interface ModelRendererProps {
    modelUrl: string;
    cameraPosition?: [number, number, number];
    fov?: number;
    near?: number;
    far?: number;
    readyFunction: (arg: boolean) => void;
}

const ModelRenderer: FC<ModelRendererProps> = ({
                                                   modelUrl,
                                                   cameraPosition = [150, 150, 150],
                                                   fov = 60,
                                                   near = 1,
                                                   far = 1000,
                                                   readyFunction,
                                               }) => {
    const cameraRef = useRef<THREE.PerspectiveCamera>(null);
    const orbitControlRef = useRef<OrbitControlsType | null>(null);

    const [modelDimensions, setModelDimensions] = useState<ModelDimensions | null>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null); // Ref for the Canvas element
    const snapshotRef = useRef<() => void>();

    const handleTakeSnapshot = () => {
        if (snapshotRef.current) {
            snapshotRef.current(); // Trigger the snapshot function inside Canvas
        }
    };

    const handleModelLoad = useCallback((dimensions: ModelDimensions) => {
        setModelDimensions(dimensions);

        readyFunction(true);
        const camera = cameraRef.current;
        const orbitControl = orbitControlRef.current;
        const canvas = canvasRef.current; // Access the canvas element



        if (camera && orbitControl && canvas) {
            const maxSize = Math.max(dimensions.width, dimensions.height, dimensions.depth);
            const far = maxSize * 5;

            const calculateFov = () =>{
                return Math.min(75, Math.max(45, maxSize*0.25));
            }
            camera.lookAt(dimensions.center);
            camera.fov = calculateFov();
            camera.near=1;
            camera.far=far;
            camera.aspect = canvas.clientWidth / canvas.clientHeight;

            camera.position.set(dimensions.width+50, dimensions.height/2, dimensions.depth+dimensions.width+100);


            camera.updateProjectionMatrix();
            orbitControl.update();
        }
    }, [readyFunction]);

    return (
        <ErrorBoundary>
            <Canvas ref={canvasRef} style={{backgroundColor: 'transparent'}}>
                {/* <Suspense fallback={<Loader />}> */}
                <PerspectiveCamera
                    ref={cameraRef}
                    makeDefault
                    // fov={fov}
                    // aspect={size.width / size.height}
                    // position={cameraPosition}
                    // near={near}
                    // far={far}
                />
                <ambientLight intensity={0.55}/>
                <directionalLight
                    position={[modelDimensions?.width ? modelDimensions.width * 2 : 100, modelDimensions?.height ? modelDimensions.height * 2 : 100, modelDimensions?.depth ? modelDimensions.depth * 2 : 100]}
                    intensity={0.85}
                />
                <directionalLight
                    position={[modelDimensions?.width ? -(modelDimensions.width * 2) : -100, modelDimensions?.height ? -(modelDimensions.height * 2) : -100, modelDimensions?.depth ? -(modelDimensions.depth * 2) : -100]}
                    intensity={0.55}
                />
                {/*<pointLight position={[0, 150, 0]} intensity={1}/>*/}
                {/*<pointLight position={[30, 50, -20]} intensity={0.3}/>*/}

                <mesh
                    rotation={[-Math.PI / 2, 0, 0]}
                    position={[0, modelDimensions?.height ? -modelDimensions.height / 2 : 120, 0]}
                    // receiveShadow={true}
                >
                    <planeGeometry
                        args={[modelDimensions?.width ? Math.ceil(modelDimensions.width * 1.25) : 200, modelDimensions?.width ? Math.ceil(modelDimensions.width * 1.25) : 200]}/>
                    <shadowMaterial opacity={0.1}/>
                    {/*<meshBasicMaterial color="#303030"/>*/}
                    {/*<meshStandardMaterial color="transparent"/>*/}


                    {/*<gridHelper
                        args={[modelDimensions?.width ? Math.ceil(modelDimensions.width * 1.25) : 100, modelDimensions?.width ? Math.ceil(modelDimensions.width * 0.20) : 20, '#606060', '#404040']}

                        // args={[120, 20, '#606060', '#404040']}
                        position={[0,0, 0]}
                        rotation={[-Math.PI / 2, 0, 0]}
                    />*/}

                </mesh>
                <Model url={modelUrl} onModelLoad={handleModelLoad}/>
                {/* </Suspense> */}
                <OrbitControls enableDamping={true} dampingFactor={1} maxDistance={modelDimensions?.width ? (
                    Math.max(modelDimensions.width, modelDimensions.height, modelDimensions.depth) * 4
                ) : 1000} ref={orbitControlRef}/>
                {/*<Snapshot ref={snapshotRef} />*/}
            </Canvas>
            {/*<button onClick={handleTakeSnapshot}>Take Snapshot</button>*/}
        </ErrorBoundary>
    );
};

export default ModelRenderer;