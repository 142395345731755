import React, {useEffect, useRef} from 'react';
import './App.css';
import Header from "./components/CarouselFirstPage/Header";
import 'bootstrap/dist/css/bootstrap.min.css';
import gsap from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import ForwardedIntroduction from "./components/IntroductionPage/Introduction";
import SideMenu from "./components/SideMenu/SideMenu";
import CardsRow from "./components/ShowcaseForCardsWith3DModel/CardsRow";
import EstimationWrapper from "./components/Estimation/EstimationWrapper";
import SwatchesScreenWrapper from './components/SwatchesOfColors/SwatchesScreenWrapper';
import throttle from "./assets/functions/throttle";

gsap.registerPlugin(ScrollTrigger);



const App : React.FC = () => {
/*    const actualSnapPoint = useRef(0);

    useEffect(() => {
        const snapPoints = document.querySelectorAll('.snap-point') as NodeListOf<HTMLElement>;
        const snapThreshold = window.innerHeight * 0.25; // 10vh equivalent

        const handleScroll = (): void => {
            snapPoints.forEach((point) => {
                const pointTop = point.offsetTop;
                const scrollPosition = window.scrollY;

                if (
                    (
                    scrollPosition >= pointTop - snapThreshold &&
                    scrollPosition <= pointTop + snapThreshold)
                    &&
                    Math.round(actualSnapPoint.current) !== Math.round(pointTop)
                ) {
                    actualSnapPoint.current = pointTop;

                    window.scrollTo({
                        top: pointTop,
                        behavior: 'smooth'
                    });
                }
            });
        };

        const throttledHandleScroll = throttle(handleScroll, 1000)

        window.addEventListener('scroll', throttledHandleScroll);

        return () => {
            window.removeEventListener('scroll', throttledHandleScroll);
        };
    }, []);*/

  return (
      <div className="mainDiv">
          <SideMenu/>
          <Header/>
          <ForwardedIntroduction/>
          <CardsRow />
          <SwatchesScreenWrapper />
          {/*<ContactWrapper />*/}
          <EstimationWrapper />
      </div>
  );
}

export default App;

/*
Unused dependencies
* @cloudinary/react
* @cloudinary/url-gen
* @fortawesome/free-brands-svg-icons
* @gsap/react
* @testing-library/react
* @testing-library/user-event
* @types/jest
* @types/node
* astro-cloudinary
Missing dependencies
* eslint-config-react-app: .\package.json
* */