import React, {useEffect, useState, useRef, useCallback} from "react";

interface UseIntersectionObserverArgs {
    root?: Element | null;
    rootMargin?: string;
    threshold?: number | number[];
    maintainObservation?: boolean;
}

const useIntersectionObserver = ({
                                     root = null,
                                     rootMargin = "0px",
                                     threshold = 0.5,
                                    maintainObservation,
                                 }: UseIntersectionObserverArgs): [boolean, React.RefObject<HTMLDivElement>] => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const elementRef = useRef<HTMLDivElement | null>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);

    const cleanupObserver = useCallback(() => {
        if (observerRef.current && elementRef.current) {
            observerRef.current.unobserve(elementRef.current);
            observerRef.current.disconnect();
            observerRef.current = null;
        }
    }, []);

    useEffect(() => {
        if(!elementRef.current) return;

        const callback = (entries: IntersectionObserverEntry[]) => {
            const [entry] = entries;
            setIsVisible(entry.isIntersecting);

            if (entry.isIntersecting && !maintainObservation) {
                cleanupObserver();
            }
        };

        observerRef.current = new IntersectionObserver(callback, {
            root,
            rootMargin,
            threshold,
        });
        

        observerRef.current.observe(elementRef.current);


        return cleanupObserver;

    }, [elementRef, root, rootMargin, threshold]);

    return [isVisible, elementRef];
};

export default useIntersectionObserver;
