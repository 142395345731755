import ColorPalete from "./ColorPalete";
import React, {useContext} from "react";
import {IsMobileContext} from "../IsMobileContext";
import "./ColorPalete.css";
import {Context} from "../Context";
import ArrowIcon from "../../assets/Icons/ArrowIcon";
import ContactWrapper from "../Contact/ContactWrapper";

const colors = ["linear-gradient(135deg, #000000 0%, #434343 100%)", "white", "gray", "black", "#b87333", "linear-gradient(135deg, #6F2DBD 0%, #A663CC 50%, #3B0086 100%)"];
const SwatchesScreenWrapper = () => {
    const {isHeightFixed} = useContext(IsMobileContext);
    const { isWidthGreaterThanHeight} =useContext(Context);
    //const isWidthGreaterThanHeight = clientWidth > clientHeight;
    // console.log(isHeightFixed);
    // console.log("isHeightFixed");

    return (
        <div className="ShowcaseWrapperSecond" id="ColorSwatchesAndContactWrapper">
            <div className="ShowcaseBodySecond snap-point" id="bodyOfFilamentDiv">
                {isWidthGreaterThanHeight ?
                (
                    <>
                    <div className="swatchesAndTextWrapper">
                        <div className="actualFilamentColorsDiv">
                            <ColorPalete quantityOfBars={colors.length}
                                         colors={colors}/>
                            <p className="arrowText">Powrót</p>
                            <ArrowIcon idOfDivToScrollInto=".outerDivHeader"/>

                        </div>
                        <div className="textNextToSwatches">
                            <ul>
                                <li>Przykładowe kolory</li>
                                <ul>
                                    <li>Obok prezentowane są dostępne kolory filamentów, w których realizacja zamówień jest zazwyczaj szybsza ze względu na ich stałą dostępność.</li>
                                </ul>
                                <li>Indywidualne zamówienia</li>
                                <ul>
                                    <li>Wszystkie zamówienia realizuję poprzez korespondencję e-mail, aby zapewnić Ci w pełni spersonalizowaną obsługę.</li>
                                </ul>
                                <li>Kalkulator i kontakt</li>
                                <ul>
                                    <li>Poniżej widoczny jest formularz kontaktowy – zapraszam do skorzystania!</li>
                                </ul>
                            </ul>
                            {/*{isHeightFixed ? <p>Policz wstępna wycene poniżej</p> : null}*/}
                        </div>
                    </div>
                    <div className="connectedContactForm">
                        <ContactWrapper />
                    </div>
                    </>
                ) :
                    (
                        <>
                            <div className="actualFilamentColorsDiv">
                                <ColorPalete quantityOfBars={colors.length}
                                             colors={colors}/>
                            </div>
                            <div className="textNextToSwatches">
                                <p>Przykładowe kolory, przy których realizacja zamówienia z reguły jest szybsza, ze
                                    względu na
                                    dostępność.</p>
                                <p>Wszystkie zamówienia są realizowane za pomocą korespondencji emailowej</p>
                            </div>
                            <div className="connectedContactForm">
                                <ContactWrapper/>
                            </div>
                        </>
                    )}
            </div>

            {isHeightFixed ? null : (
                <div className="ShowcaseFooterSecond">
                 <p>Policz wstępna wycene poniżej</p>
            </div>
                )}
        </div>
    );
}

export default SwatchesScreenWrapper;