import React, {useContext, useEffect, useRef, useState} from 'react';
import "./ContactWrapper.css";
import emailjs from '@emailjs/browser';
import {Context} from "../Context";
import useDelayUnmount from "../../assets/hooks/useDelayUnmount";

// console.log(emailjs);
// console.log(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);


const ContactWrapper = () => {
    const {isWidthGreaterThanHeight} = useContext(Context);
    const form = useRef<HTMLFormElement>(null);
    //const isWidthGreaterThanHeight = clientWidth > clientHeight;
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const shouldRenderPopup = useDelayUnmount(showSuccessPopup, 500);



    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!form.current) {
            console.log('Form reference is null.');
            return;
        }

        emailjs
            .sendForm('contact_service', 'contact_form', form.current || '',  {
                publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY || '',
            })
            .then(
                () => {
                    console.log('SUCCESS!');
                    setShowSuccessPopup(true);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
    };

    useEffect(() => {
        if (showSuccessPopup) {
            const timer = setTimeout(() => {
                setShowSuccessPopup(false);
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [showSuccessPopup]);

    return(
        <div className="ContactWrapper" id="contact">
            <div className="ContactWrapperBody">
                <form ref={form} onSubmit={sendEmail}>
                    <label>Imię</label>
                    <input type="text" name="user_name"/>
                    <label>Email</label>
                    <input type="email" name="user_email"/>
                    <label>Wiadomość</label>
                    <textarea name="message"/>
                    <input type="hidden" name="request_number" value={Date.now().toString()}/>
                    <input type="submit" value="Wyślij"/>
                </form>

                {shouldRenderPopup && (
                    <div className={`success-popup ${showSuccessPopup ? "mount" : "unmount"}`}>
                        <p>Wiadomość została wysłana poprawnie!</p>
                        <button onClick={() => setShowSuccessPopup(false)}>Zamknij</button>
                    </div>
                )}
            </div>



            {isWidthGreaterThanHeight ? null :
                (
            <div className="ContactWrapperFooter">
                <p>Policz wstępna wycene poniżej</p>
            </div>
            )}
        </div>
    );
}

export default ContactWrapper;