import React, {useEffect, useRef} from "react";
import { Carousel } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import "./CarouselForModelImages.css";
// import {getKeySrc} from "../../assets/functions/ImportFromDirectory";

interface CarouselForModelImagesProps{
    modelImages: Record<string, string>,
}
const CarouselForModelImages = ({modelImages}: CarouselForModelImagesProps) => {



    const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);

    useEffect(() => {
        const videos = document.querySelectorAll('.CarouselWithinCardSingleVideo');

        // Function to handle fullscreen changes
        const handleFullscreenChange = () => {
            videos.forEach((video) => {
                if (document.fullscreenElement === video) {
                    console.log('Video is in fullscreen mode');
                    // document.documentElement.style.setProperty('overflow-y', "hidden");
                    // console.log("wylaczylem scrolla");
                } else {
                    console.log('Exited fullscreen mode or another element is in fullscreen');
                    // document.body.style.overflow = 'auto';
                }
            });
        };

        // Add the fullscreenchange listener
        document.addEventListener('fullscreenchange', handleFullscreenChange);

        // Cleanup event listener on component unmount
        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
        };
    }, []);

    return(
        <div className="CarouselForModelImagesWrapper">
            <Carousel className="carouselWithinCard"
                      interval={null}
            >
                {Object.entries(modelImages).map(([key, item], index) => (
                    <Carousel.Item key={index} interval={5500}>
                        {/* Check if the item is an MP4 file */}
                        {item.endsWith('.mp4') ? (
                            <video
                            preload="false"
                            ref={(el) => (videoRefs.current[index]=el)}
                            id={`${index}" video"`}
                            loop muted autoPlay={true}
                            className="CarouselWithinCardSingleVideo"
                            width="100%"
                            height="100%"
                            >
                                <source src={item} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <Image src={item} loading="lazy" className="CarouselWithinCardSingleImage" />
                        )}
                        {/*<Image src={item} className="CarouselWithinCardSingleImage"/>*/}
                    </Carousel.Item>
                ))}
            </Carousel>
            {/*<Carousel className="carousel-slide"
                      prevIcon={""} nextIcon={""}
                      interval={null}
            >

                {modelImages.map((item, index) => (
                    <Carousel.Item key={index} interval={5500}>
                        <Image src={item} className="carousel-single-image"/>
                    </Carousel.Item>
                ))}
            </Carousel>*/}
        </div>
    );
}

export default CarouselForModelImages;