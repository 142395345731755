import React, {createContext, ReactNode, useCallback, useEffect, useState} from 'react';
type SizeType = "mobile_high" | "mobile_low" | "desktop_low" | "desktop_high" | null;



export type IsMobileContextProps = {
    isHeightFixed: boolean;
    size: SizeType;

};

export const IsMobileContext = createContext<IsMobileContextProps>({
    isHeightFixed: false,
    size: null,
});

type IsMobileProviderProps = {
    children: ReactNode;
};

export const IsMobileProvider: React.FC<IsMobileProviderProps> = ({ children }) => {
    const [isHeightFixed, setIsHeightFixed] = useState(false);
    const [size, setSize] = useState<SizeType>(null);

    useEffect(() => {
        const someDivWith100svh = document.getElementById("bodyOfFilamentDiv");

        if(someDivWith100svh){
            if(someDivWith100svh.offsetHeight === window.innerHeight){
                setIsHeightFixed(true);
            }
            else{
                setIsHeightFixed(false);
            }
        }

        const width = window.innerWidth;
        const devicePixelRatio = window.devicePixelRatio;
        if(width<650) setSize(`mobile_${devicePixelRatio >= 1.5 ? 'high' : 'low'}`)
        if(width>650) setSize(`desktop_${devicePixelRatio >= 1.5 ? 'high' : 'low'}`)
    }, [])

    return (
        <IsMobileContext.Provider value={{ isHeightFixed, size }}>
            {children}
        </IsMobileContext.Provider>
    );
};