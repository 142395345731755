import TextForIntroduction from "./TextForIntroduction";
import ArrowIcon from '../../assets/Icons/ArrowIcon';
import React, {useContext} from "react";
import "./Introduction.css";
import {IsMobileContext} from "../IsMobileContext";


const Introduction = () => {
    const {isHeightFixed} = useContext(IsMobileContext);


    return(
        <div className="IntroductionWrapper" id="aboutMe">
          <div className="outerDivIntroduction snap-point">
              <ArrowIcon idOfDivToScrollInto=".ShowcaseWrapperFirst"/>
              <TextForIntroduction />

          </div>
            {isHeightFixed ? null : (
            <div className="IntroductionFooter">
            </div>
            )}
        </div>
    );
};

export default Introduction;