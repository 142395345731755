import React, {ReactNode, useEffect, useState} from "react";


interface fadeProps {
    show: boolean,
    children: ReactNode,
}

const Fade = ({ show, children }: fadeProps) => {
    const [render, setRender] = useState(show);

    useEffect(() => {
        if (show) setRender(true);
    }, [show]);

    const onAnimationEnd = () => {
        if (!show) setRender(false);
    };

    return render ? (
        <div
            className="fadeMain"
            style={{ animation: `${show ? "fadeIn" : "fadeOut"} 1s forwards` }}
            onAnimationEnd={onAnimationEnd}
        >
            {children}
        </div>
    ) : null;
};

export default Fade;