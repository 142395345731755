import React, { useState } from "react";
import AccordionItem from "./AccordionItem";

const faqs = [
    {
        question: "Jakich materiałów używacie do druku 3D?",
        answer: "Używamy różnych materiałów, w tym PLA, ABS i PETG, aby spełnić potrzeby Twojego projektu.",
    },
    {
        question: "Ile czasu zajmuje wykonanie i wysyłka zamówienia na indywidualne zamówienie?",
        answer: "Czas realizacji zależy głównie od wielkości modelu lub liczby zamawianych elementów. W przypadku pojedynczych, małych przedmiotów, czas wykonania wraz z modelowaniem i przesyłką zazwyczaj wynosi około tygodnia."
    },
    {
        question: "Czy oferujecie wysyłkę międzynarodową?",
        answer: "To zależy od potrzeb klienta. Jesteśmy gotowi wysłać zamówienie w dowolne miejsce, ale musimy to ustalić.",
    },
    {
        question: "Czy mogę zamówić własny projekt?",
        answer: "Oczywiście! Zapraszamy do składania zamówień na indywidualne projekty.",
    },
    {
        question: "Jakie są wasze opcje płatności?",
        answer: "Oferujemy płatność tradycyjnym przelewem oraz BLIK. Wszystkie informacje dotyczące płatności zostaną przekazane w korespondencji mailowej podczas finalizowania zamówienia."
    },
];
function Accordion() {
    const [activeIndex, setActiveIndex] = useState<number | null>(null);

    const handleClick = (index: number) => {
        // Toggle the active index
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <ul>
            {faqs.map((faq, index) => (
                <AccordionItem
                    faq={faq}
                    index={index}
                    isActive={index === activeIndex}
                    onClick={handleClick}
                    key={index}
                />
            ))}
        </ul>
    );
}

export default Accordion;
