import React from 'react';
import "./Header.css";

const HeaderText: React.FC = () => {
    return (
        <div className="headerTextContainer">
            <p>Druk gotowych modeli</p>
            <p>Projektowanie</p>
            <p>Sprzedaż</p>
        </div>
    );
}

export default HeaderText;
